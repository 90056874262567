var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { lg: "8" } },
                [
                  _c(
                    "b-form",
                    {
                      ref: "firstTabForm",
                      on: { submit: _vm.onSubmit, reset: _vm.onReset }
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Order Name:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "order-name",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "order-name",
                              required: "",
                              oninvalid: "setCustomValidity('Please enter');",
                              oninput: "setCustomValidity('')",
                              placeholder: "Please enter"
                            },
                            model: {
                              value: _vm.form.batchName,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "batchName", $$v)
                              },
                              expression: "form.batchName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Order No.:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "order-number"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "order-number",
                              placeholder: "Automatic system generation",
                              disabled: ""
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Patch Type:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "patch-type",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-wrap" },
                            _vm._l(_vm.types.patchType, function(label, index) {
                              return _c(
                                "b-form-checkbox",
                                {
                                  key: index,
                                  staticClass: "mr-2 mt-2",
                                  attrs: { required: true, value: label.id },
                                  on: {
                                    change: function($event) {
                                      return _vm.changeDeviceId(label.id)
                                    }
                                  },
                                  model: {
                                    value: _vm.form.type,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "type", $$v)
                                    },
                                    expression: "form.type"
                                  }
                                },
                                [_vm._v(" " + _vm._s(label.typeName) + " ")]
                              )
                            }),
                            1
                          )
                        ]
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Device Model:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "device-model",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              id: "device-model",
                              options: _vm.types.deviceModel,
                              "value-field": "id",
                              "text-field": "modelName",
                              required: "",
                              oninvalid: "setCustomValidity('Please select');",
                              oninput: "setCustomValidity('')"
                            },
                            on: {
                              change: function($event) {
                                return _vm.selectPatchVoltageByDeviceId(
                                  _vm.form.deviceId
                                )
                              }
                            },
                            model: {
                              value: _vm.form.deviceId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "deviceId", $$v)
                              },
                              expression: "form.deviceId"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Production Quantity:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "production-quantity",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "production-quantity",
                              type: "number",
                              min: "0",
                              required: "",
                              oninvalid: "setCustomValidity('Please enter');",
                              oninput: "setCustomValidity('')",
                              placeholder: "Please Enter"
                            },
                            model: {
                              value: _vm.form.batchQuantity,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "batchQuantity", $$v)
                              },
                              expression: "form.batchQuantity"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Custom Write Url:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "custom-write-url",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              staticStyle: { "margin-top": "5px" },
                              attrs: {
                                id: "custom-write-url",
                                name: "customWriteUrl",
                                value: 1,
                                "unchecked-value": 0,
                                switch: "",
                                size: "lg"
                              },
                              model: {
                                value: _vm.form.customWriteUrl,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "customWriteUrl", $$v)
                                },
                                expression: "form.customWriteUrl"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.form.customWriteUrl == 1 ? "Yes" : "No"
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm.form.customWriteUrl == 1
                        ? _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "URL:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "write-url",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "write-url",
                                  required: "",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput: "setCustomValidity('')",
                                  placeholder: "Please enter"
                                },
                                model: {
                                  value: _vm.form.url,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "url", $$v)
                                  },
                                  expression: "form.url"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.customWriteUrl == 0
                        ? _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "URL:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "url",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  id: "url",
                                  options: _vm.types.urlSetting,
                                  "value-field": "id",
                                  "text-field": "url",
                                  required: "",
                                  oninvalid:
                                    "setCustomValidity('Please select');",
                                  oninput: "setCustomValidity('')"
                                },
                                model: {
                                  value: _vm.form.url,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "url", $$v)
                                  },
                                  expression: "form.url"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.showVoltage
                        ? _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Max seconds:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "maxFrequency",
                                "label-class": "requiredRow",
                                description:
                                  "To Prevent Counterfeit by Refill, Cartridge Will Stop Working After Time Reaches."
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "maxFrequency",
                                  type: "number",
                                  min: "0",
                                  required: "",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput:
                                    "setCustomValidity('');value = value.replace(/[^\\d.]/g, '').replace(/\\.{2,}/g,'.').replace(/^\\./g, '').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^(\\-)*(\\d+)\\.(\\d\\d).*$/, '$1$2.$3');if(value>60000)value=60000;",
                                  placeholder: "Please Enter"
                                },
                                model: {
                                  value: _vm.form.maxFrequency,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "maxFrequency", $$v)
                                  },
                                  expression: "form.maxFrequency"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.showVoltage
                        ? _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Voltage1:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "curveOne",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "voltage-one" },
                                _vm._l(_vm.temperatureList, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "voltage-number"
                                    },
                                    [_vm._v(" " + _vm._s(item.value) + " v ")]
                                  )
                                }),
                                0
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "voltage-two",
                                  staticStyle: { width: "100%" }
                                },
                                _vm._l(_vm.temperatureList, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "voltage-list" },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-plus voltage-plus",
                                        on: {
                                          click: function($event) {
                                            return _vm.onVoltagePlus(index)
                                          }
                                        }
                                      }),
                                      _c("el-slider", {
                                        attrs: {
                                          vertical: "",
                                          step: _vm.step,
                                          min: _vm.min,
                                          max: _vm.max,
                                          height: "150px"
                                        },
                                        model: {
                                          value: item.value,
                                          callback: function($$v) {
                                            _vm.$set(item, "value", $$v)
                                          },
                                          expression: "item.value"
                                        }
                                      }),
                                      _c("i", {
                                        staticClass:
                                          "el-icon-minus voltage-reduce",
                                        on: {
                                          click: function($event) {
                                            return _vm.onVoltageReduce(index)
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                }),
                                0
                              ),
                              _c(
                                "div",
                                { staticClass: "voltage-three" },
                                _vm._l(8, function(index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "voltage-number"
                                    },
                                    [_vm._v(" " + _vm._s(index) + "s ")]
                                  )
                                }),
                                0
                              ),
                              _c("p", [
                                _vm._v(
                                  " The Voltage curve is between 0.1v-4.2v, after the 8th second, the same as the 8th second "
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm.showVoltage
                        ? _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Voltage2 Settings:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "voltage-settings",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  staticStyle: { "margin-top": "5px" },
                                  attrs: {
                                    id: "voltage-settings",
                                    name: "voltageSetting",
                                    value: 1,
                                    "unchecked-value": 0,
                                    switch: "",
                                    size: "lg"
                                  },
                                  model: {
                                    value: _vm.voltageSettings,
                                    callback: function($$v) {
                                      _vm.voltageSettings = $$v
                                    },
                                    expression: "voltageSettings"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.voltageSettings == 1 ? "Yes" : "No"
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.voltageSettings == 1
                        ? _c(
                            "b-form-group",
                            {
                              attrs: {
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "curveTwo"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "voltage-one" },
                                _vm._l(_vm.temperatureList1, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "voltage-number"
                                    },
                                    [_vm._v(" " + _vm._s(item.value) + " v ")]
                                  )
                                }),
                                0
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "voltage-two",
                                  staticStyle: { width: "100%" }
                                },
                                _vm._l(_vm.temperatureList1, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "voltage-list" },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-plus voltage-plus",
                                        on: {
                                          click: function($event) {
                                            return _vm.onVoltagePlus1(index)
                                          }
                                        }
                                      }),
                                      _c("el-slider", {
                                        attrs: {
                                          vertical: "",
                                          step: _vm.step,
                                          min: _vm.min,
                                          max: _vm.max,
                                          height: "150px"
                                        },
                                        model: {
                                          value: item.value,
                                          callback: function($$v) {
                                            _vm.$set(item, "value", $$v)
                                          },
                                          expression: "item.value"
                                        }
                                      }),
                                      _c("i", {
                                        staticClass:
                                          "el-icon-minus voltage-reduce",
                                        on: {
                                          click: function($event) {
                                            return _vm.onVoltageReduce1(index)
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                }),
                                0
                              ),
                              _c(
                                "div",
                                { staticClass: "voltage-three" },
                                _vm._l(8, function(index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "voltage-number"
                                    },
                                    [_vm._v(" " + _vm._s(index) + "s ")]
                                  )
                                }),
                                0
                              ),
                              _c("p", [
                                _vm._v(
                                  " The Voltage curve is between 0.1v-4.2v, after the 8th second, the same as the 8th second "
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm.voltageSettings == 1
                        ? _c(
                            "b-form-group",
                            {
                              ref: "groupNumberSetting",
                              attrs: {
                                label: "Position:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "position",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex flex-wrap" },
                                _vm._l(_vm.types.gearSetting, function(
                                  label,
                                  index
                                ) {
                                  return _c(
                                    "b-form-checkbox",
                                    {
                                      key: index,
                                      staticClass: "mr-2 mt-2",
                                      attrs: {
                                        required: true,
                                        value: label.value
                                      },
                                      model: {
                                        value: _vm.form.position,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "position", $$v)
                                        },
                                        expression: "form.position"
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(label.text) + " ")]
                                  )
                                }),
                                1
                              )
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Group Number Settings:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "group-number-settings",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              staticStyle: { "margin-top": "5px" },
                              attrs: {
                                id: "group-number-settings",
                                name: "groupNumberSetting",
                                value: 1,
                                "unchecked-value": 0,
                                switch: "",
                                size: "lg"
                              },
                              model: {
                                value: _vm.groupNumberSetting,
                                callback: function($$v) {
                                  _vm.groupNumberSetting = $$v
                                },
                                expression: "groupNumberSetting"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.groupNumberSetting == 1 ? "Yes" : "No"
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm.groupNumberSetting == 1
                        ? _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Group Number:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "group-number",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex flex-wrap" },
                                _vm._l(_vm.types.groupNumberSetting, function(
                                  label,
                                  index
                                ) {
                                  return _c(
                                    "b-form-checkbox",
                                    {
                                      key: index,
                                      staticClass: "mr-2 mt-2",
                                      attrs: {
                                        required: true,
                                        value: label.id
                                      },
                                      model: {
                                        value: _vm.form.groupNumber,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "groupNumber", $$v)
                                        },
                                        expression: "form.groupNumber"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(label.groupName) + " "
                                      )
                                    ]
                                  )
                                }),
                                1
                              )
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Custom Write Tag:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "custom-write-tag",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              staticStyle: { "margin-top": "5px" },
                              attrs: {
                                id: "custom-write-tag",
                                name: "customWriteTag",
                                value: 1,
                                "unchecked-value": 0,
                                switch: "",
                                size: "lg"
                              },
                              model: {
                                value: _vm.customWriteTag,
                                callback: function($$v) {
                                  _vm.customWriteTag = $$v
                                },
                                expression: "customWriteTag"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.customWriteTag == 1 ? "Yes" : "No"
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm.customWriteTag == 1
                        ? _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Tag:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "write-tag",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "write-tag",
                                  required: "",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput: "setCustomValidity('')",
                                  placeholder: "Please enter"
                                },
                                model: {
                                  value: _vm.form.writeTag,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "writeTag", $$v)
                                  },
                                  expression: "form.writeTag"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Remarks:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "remarks"
                          }
                        },
                        [
                          _c("b-form-textarea", {
                            attrs: {
                              id: "remarks",
                              placeholder: "Other Notes",
                              rows: "3",
                              "max-rows": "6"
                            },
                            model: {
                              value: _vm.form.remarks,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "remarks", $$v)
                              },
                              expression: "form.remarks"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticStyle: { "text-align": "center" },
                          attrs: { lg: "12" }
                        },
                        [
                          _c(
                            "b-button",
                            { attrs: { type: "submit", variant: "primary" } },
                            [_vm._v("Confirm")]
                          ),
                          _c(
                            "b-button",
                            {
                              staticStyle: { "margin-left": "30px" },
                              attrs: { type: "reset" }
                            },
                            [_vm._v("Cancel")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }